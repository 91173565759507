<template>
    <v-select v-model="group" :items="groupCols" simple />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { issuesHeaders } from '@/config/views';

export default {
    name: 'selector-group',
    data: () => ({
        group: undefined,
    }),
    components: {},
    mounted() {
        this.group = this.value;
    },
    props: {
        value: {},
    },
    watch: {
        group: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.group = value;
        },
    },
    computed: {
        groupCols() {
            return [
                {
                    value: '',
                    text: '-',
                },
            ].concat(
                issuesHeaders.map((h) => {
                    const col = Object.assign({}, h);
                    col.text = this.$t(col.text);
                    return col;
                }),
            );
        },
    },
};
</script>
