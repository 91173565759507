<template>
    <v-container>
        <v-row align="center">
            <v-col class="py-0" cols="12" md="2">
                <v-select v-model="filter.field" :items="attributes" simple />
            </v-col>
            <v-col class="py-0" cols="12" md="3">
                <v-select v-if="filter.field" v-model="filter.operator" :items="operators" simple> </v-select>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="filter.operator">
                <v-select chips v-if="valueTypes === 'list'" v-model="filter.value" :items="values" multiple />
                <v-text-field v-if="valueTypes === 'input'" v-model="filter.value" />
                <template v-if="valueTypes === 'date'">
                    <v-container>
                        <v-row>
                            <v-col class="py-0" cols="12" md="3">
                                <v-select v-model="dateFilter" :items="values" />
                            </v-col>
                            <v-col class="py-0" cols="12" md="6" v-if="dateFilter === 'manual'">
                                <date-selector v-model="filter.value" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-col>
            <v-col class="py-0" cols="12" md="1">
                <v-btn icon v-if="allowAddingFilter" @click="addFilter">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="filters.length > 0">
            <v-col cols="12" md="12">
                <v-subheader v-text="$t('views.filters_applied')" />
                <v-chip-group column>
                    <v-chip
                        label
                        outlined
                        close
                        color="success"
                        v-for="(filter, index) in filters"
                        :key="filter.field"
                        @click:close="removeFilter(index)"
                    >
                        {{ filter.label }}
                    </v-chip>
                </v-chip-group>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import dateSelector from '@/components/selectors/simple/date';
import { format, parseISO } from 'date-fns';
import OrderBy from 'lodash/orderBy';
import CamelCase from 'lodash/camelCase';

export default {
    name: 'views-filter',
    data: () => ({
        filter: {
            field: undefined,
            operator: undefined,
            value: undefined,
        },
        filters: [],
        dateFilter: 'manual',
    }),
    props: {
        value: {},
    },
    mounted() {
        this.filters = this.value.map((f) => {
            f.label = this.filterToLabel(f);
            return f;
        });
    },
    components: {
        dateSelector,
    },
    watch: {
        'filter.field': function (value) {
            (this.filter.operator = undefined), (this.filter.value = undefined);
        },
        filters: function (value) {
            this.$emit('input', this.filters);
        },
        value: function (value) {
            this.filters = value;
        },
        dateFilter: function (value) {
            if (value !== 'manual') {
                this.filter.value = value;
            }
        },
    },
    computed: {
        ...mapGetters([
            'project',
            'projectUsers',
            'projectVersions',
            'trackers',
            'status',
            'priorities',
            'severities',
            'teams',
        ]),
        attributes() {
            const attributes = [
                {
                    text: this.$t('issues.attributes.tracker'),
                    value: 'tracker',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.status'),
                    value: 'status',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.severity'),
                    value: 'severity',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.priority'),
                    value: 'priority',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.module'),
                    value: 'module',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.category'),
                    value: 'category',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.assignedTo'),
                    value: 'assignedTo',
                    operators: ['$in', '$nin', '$emptyValue'],
                },
                {
                    text: this.$t('issues.attributes.createdBy'),
                    value: 'createdBy',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.versionImpact'),
                    value: 'versionImpact',
                    operators: ['$in', '$nin', '$emptyArray'],
                },
                {
                    text: this.$t('issues.attributes.versionWanted'),
                    value: 'versionWanted',
                    operators: ['$in', '$nin', '$emptyArray'],
                },
                {
                    text: this.$t('issues.attributes.versionTarget'),
                    value: 'versionTarget',
                    operators: ['$in', '$nin', '$emptyArray'],
                },
                {
                    text: this.$t('issues.attributes.$versions'),
                    value: '$versions',
                    operators: ['$in', '$nin', '$emptyArray'],
                },
                {
                    text: this.$t('issues.attributes.name'),
                    value: 'name',
                    operators: ['$eq', '$contain', '$startWith'],
                },
                {
                    text: this.$t('issues.attributes.time'),
                    value: 'time',
                    operators: ['$emptyValue', '$gt', '$lt', '$gte', '$lte'],
                },
                {
                    text: this.$t('issues.attributes.createdAt'),
                    value: 'createdAt',
                    operators: ['$dateeq', '$dategt', '$datelt'],
                },
                {
                    text: this.$t('issues.attributes.updatedAt'),
                    value: 'updatedAt',
                    operators: ['$dateeq', '$dategt', '$datelt'],
                },
                {
                    text: this.$t('issues.attributes.startedAt'),
                    value: 'startedAt',
                    operators: ['$dateeq', '$dategt', '$datelt'],
                },
                {
                    text: this.$t('issues.attributes.endedAt'),
                    value: 'endedAt',
                    operators: ['$dateeq', '$dategt', '$datelt'],
                },
                {
                    text: this.$t('issues.attributes.releaseNote'),
                    value: 'releaseNote',
                    operators: ['$emptyValue', '$notEmptyValue'],
                },
                {
                    text: this.$t('issues.attributes.supportClient'),
                    value: 'supportClient',
                    operators: ['$eq', '$contain', '$startWith'],
                },
                {
                    text: this.$t('issues.attributes.supportInput'),
                    value: 'supportInput',
                    operators: ['$eq', '$contain', '$startWith'],
                },
                {
                    text: this.$t('issues.attributes.supportOutput'),
                    value: 'supportOutput',
                    operators: ['$eq', '$contain', '$startWith'],
                },
                {
                    text: this.$t('issues.attributes.trackingCreatedBy'),
                    value: 'tracking.createdBy',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.trackingDate'),
                    value: 'tracking.date',
                    operators: ['$dateeq', '$dategt', '$datelt'],
                },
                {
                    text: this.$t('issues.attributes.watchers'),
                    value: 'watchers',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.interesteds'),
                    value: 'interesteds',
                    operators: ['$in', '$nin'],
                },
                {
                    text: this.$t('issues.attributes.sprint'),
                    value: 'sprint',
                    operators: ['$in', '$nin'],
                },
            ];

            return OrderBy(attributes, ['text'], ['asc']);
        },
        operators() {
            if (this.filter.field) {
                const operators = this.attributes.find((a) => a.value === this.filter.field).operators;
                return operators.map((o) => {
                    return { value: o, text: this.$t('issues.filters.' + o) };
                });
            }
            return [];
        },
        valueTypes() {
            if (['$in', '$nin'].includes(this.filter.operator)) {
                return 'list';
            }
            if (['$eq', '$contain', '$startWith', '$gt', '$lt', '$gte', '$lte'].includes(this.filter.operator)) {
                return 'input';
            }
            if (['$dategt', '$datelt', '$dateeq'].includes(this.filter.operator)) {
                return 'date';
            }
            if (['$emptyArray', '$emptyValue', '$notEmptyValue'].includes(this.filter.operator)) {
                return '';
            }
            return undefined;
        },
        values() {
            if (this.filter.field === 'tracker') {
                return this.trackers.map((t) => {
                    return {
                        value: t.name,
                        text: t.label,
                    };
                });
            }
            if (this.filter.field === 'status') {
                return [
                    { value: '%statusOpen%', text: '<' + this.$t('status.attributes.open') + '>' },
                    { value: '%statusClose%', text: '<' + this.$t('status.attributes.close') + '>' },
                ].concat(
                    this.status.map((t) => {
                        return {
                            value: t.name,
                            text: t.label,
                        };
                    }),
                );
            }
            if (this.filter.field === 'severity') {
                return this.severities.map((t) => {
                    return {
                        value: t.value,
                        text: t.label,
                    };
                });
            }
            if (this.filter.field === 'priority') {
                return this.priorities.map((t) => {
                    return {
                        value: t.value,
                        text: t.label,
                    };
                });
            }

            if (this.filter.field === 'module') {
                return this.project
                    ? this.project.modules.map((m) => {
                          return {
                              value: m,
                              text: m,
                          };
                      })
                    : [];
            }

            if (this.filter.field === 'category') {
                return this.project
                    ? this.project.categories.map((t) => {
                          return {
                              value: t.name,
                              text: t.name,
                          };
                      })
                    : [];
            }

            if (
                ['assignedTo', 'createdBy', 'tracking.createdBy', 'interesteds', 'watchers'].includes(this.filter.field)
            ) {
                return this.availableUsers;
            }

            if (['createdAt', 'updatedAt', 'startedAt', 'endedAt', 'tracking.date'].includes(this.filter.field)) {
                return [
                    { value: ['%today%'], text: '<' + this.$t('issues.filters.constants.dateToday') + '>' },
                    { value: ['%tomorrow%'], text: '<' + this.$t('issues.filters.constants.dateTomorrow') + '>' },
                    { value: ['%thisWeek%'], text: '<' + this.$t('issues.filters.constants.dateThisWeek') + '>' },
                    { value: ['%lastWeek%'], text: '<' + this.$t('issues.filters.constants.dateLastWeek') + '>' },
                    { value: ['%twoWeeks%'], text: '<' + this.$t('issues.filters.constants.dateTwoWeeks') + '>' },
                    { value: 'manual', text: this.$t('issues.filters.constants.dateManual') },
                ];
            }

            if (['versionImpact', 'versionWanted', 'versionTarget', '$versions'].includes(this.filter.field)) {
                const versions = [
                    { value: '%versionDevelop%', text: '<' + this.$t('lists.versionStatus.develop') + '>' },
                    { value: '%nextVersionDevelop%', text: '<' + this.$t('lists.versionStatus.nextDevelop') + '>' },
                    { value: '%versionMajor%', text: '<' + this.$t('lists.versionStatus.major') + '>' },
                    { value: '%versionMinor%', text: '<' + this.$t('lists.versionStatus.minor') + '>' },
                    { value: '%versionFix%', text: '<' + this.$t('lists.versionStatus.fix') + '>' },
                ];
                if (this.project) {
                    return versions.concat(this.projectVersions);
                } else {
                    return versions;
                }
            }

            if (this.filter.field === 'sprint') {
                return OrderBy(
                    this.teams
                        .filter((t) => {
                            if (this.project) {
                                return t.projects.includes(this.project.name);
                            }
                            return true;
                        })
                        .map((t) => t.sprints)
                        .reduce((a, b) => a.concat(b), [])
                        .map((s) => {
                            return {
                                value: s.name,
                                text:
                                    s.name +
                                    ' (' +
                                    format(parseISO(s.startedAt), 'dd MMM') +
                                    ' - ' +
                                    format(parseISO(s.endedAt), 'dd MMM') +
                                    ')',
                            };
                        }),
                    ['text'],
                    ['asc'],
                );
            }

            return [];
        },
        allowAddingFilter() {
            if (this.valueTypes !== undefined) {
                if (this.valueTypes === '') {
                    return true;
                } else {
                    return this.filter.value;
                }
            }
            return false;
        },
        availableUsers() {
            let users = [{ value: '%me%', text: this.$t('users.connected') }];
            if (this.project) {
                users = users.concat(this.projectUsers);
            }
            return users;
        },
    },
    methods: {
        initFilter() {
            this.filter = {
                field: undefined,
                operator: undefined,
                value: undefined,
            };
            this.dateFilter = 'manual';
        },
        addFilter() {
            if (this.allowAddingFilter) {
                let value = JSON.stringify(this.filter.value);
                if (this.filter.value instanceof Array) {
                    value = this.filter.value.join(',');
                } else {
                    value = this.filter.value;
                }

                this.filters.push({
                    field: this.filter.field,
                    operator: this.filter.operator,
                    value: value,
                    label: this.filterToLabel(this.filter),
                });

                this.initFilter();
            }
        },
        removeFilter(index) {
            this.filters.splice(index, 1);
        },
        filterToLabel(filter) {
            let valueL = [value];
            let value = filter.value;
            if (typeof value === 'string') {
                value = value.split(',');
            }

            if (value instanceof Array) {
                valueL = value.map((v) => {
                    if (this.listValue(filter)) {
                        return this.$t(this.listValue(filter) + v);
                    } else if (this.listUser(filter)) {
                        return this.availableUsers.filter((u) => u.value === v)[0].text;
                    } else {
                        return this.replaceVariables(v);
                    }
                });
            }
            if (filter.value instanceof Date) {
                valueL = [format(filter.value, 'dd/MM/yyyy')];
            }

            if (['$dategt', '$datelt', '$dateeq'].includes(filter.operator) && !(filter.value instanceof Date)) {
                valueL = [format(parseISO(filter.value), 'dd/MM/yyyy')];
            }

            const label = `${this.$t('issues.attributes.' + CamelCase(filter.field))} ${this.$t(
                'issues.filters.' + filter.operator,
            )} ${valueL.join(', ')}`;

            return label;
        },
        listValue(filter) {
            if (filter.field === 'tracker') {
                return 'lists.trackers.';
            }
            if (filter.field === 'status') {
                return 'lists.status.';
            }
            if (filter.field === 'severity') {
                return 'lists.severities.';
            }
            if (filter.field === 'priority') {
                return 'lists.priorities.';
            }
            return undefined;
        },
        listUser(filter) {
            return ['assignedTo', 'createdBy', 'tracking.createdBy', 'interesteds', 'watchers'].includes(filter.field);
        },
        replaceVariables(value) {
            if (value === '%versionDevelop%') {
                return '<' + this.$t('lists.versionStatus.develop') + '>';
            }
            if (value === '%nextVersionDevelop%') {
                return '<' + this.$t('lists.versionStatus.nextDevelop') + '>';
            }
            if (value === '%versionMajor%') {
                return '<' + this.$t('lists.versionStatus.major') + '>';
            }
            if (value === '%versionMinor%') {
                return '<' + this.$t('lists.versionStatus.minor') + '>';
            }
            if (value === '%versionFix%') {
                return '<' + this.$t('lists.versionStatus.fix') + '>';
            }
            if (value === '%statusOpen%') {
                return '<' + this.$t('projects.status.open') + '>';
            }
            if (value === '%statusClose%') {
                return '<' + this.$t('projects.status.close') + '>';
            }

            if (value === '%today%') {
                return '<' + this.$t('issues.filters.constants.dateToday') + '>';
            }
            if (value === '%today%') {
                return '<' + this.$t('issues.filters.constants.dateTomorrow') + '>';
            }
            if (value === '%thisWeek%') {
                return '<' + this.$t('issues.filters.constants.dateThisWeek') + '>';
            }
            if (value === '%twoWeeks%') {
                return '<' + this.$t('issues.filters.constants.dateTwoWeeks') + '>';
            }
            if (value === '%lastWeek%') {
                return '<' + this.$t('issues.filters.constants.dateLastWeek') + '>';
            }

            return value;
        },
    },
};
</script>
