<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <v-list class="elevation-1 overflow-y-auto" dense max-height="600px">
                    <v-subheader v-text="$t('views.cols_available')" />
                    <draggable v-model="avaiblesCols" group="cols">
                        <v-list-item class="list-draggable" v-for="col in avaiblesCols" :key="'avai-' + col">
                            <v-list-item-content>
                                <v-list-item-title>{{ colLabel(col) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-col>
            <v-col cols="12" md="6">
                <v-list class="elevation-1 overflow-y-auto" dense max-height="600px">
                    <v-subheader v-text="$t('views.cols_selected')" />
                    <draggable v-model="cols" group="cols">
                        <v-list-item class="list-draggable" v-for="(col, index) in cols" :key="'col-' + index">
                            <v-list-item-content>
                                <v-list-item-title>{{ colLabel(col) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import { issuesHeaders } from '@/config/views';

export default {
    name: 'selector-col',
    data: () => ({
        cols: [],
    }),
    components: {
        draggable,
    },
    mounted() {
        this.cols = this.value;
    },
    props: {
        value: {},
    },
    watch: {
        cols: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.cols = value;
        },
    },
    computed: {
        avaiblesCols: {
            get: function () {
                return issuesHeaders.filter((h) => !this.cols.includes(h.value)).map((h) => h.value);
            },
            set: function (value) {},
        },
    },
    methods: {
        colLabel(v) {
            const col = issuesHeaders.find((h) => h.value === v);
            return this.$t(col.selector ? col.selector : col.text);
        },
    },
};
</script>
<style scoped>
.list-draggable {
    cursor: pointer;
}
</style>
