<template>
    <v-select v-model="localUsers" :items="usersItems" :outlined="outlined" chips :label="label" multiple></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
export default {
    name: 'selector-users',
    data: () => ({
        localUsers: [],
    }),
    created() {
        if (this.users.length === 0) {
            this.findUsers();
        }
    },
    mounted() {
        this.localUsers = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {},
    },
    watch: {
        localUsers: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localUsers = value;
        },
    },
    computed: {
        ...mapGetters(['users']),
        usersItems() {
            return OrderBy(
                this.users.map((u) => {
                    return {
                        text: u.fullname,
                        value: u.name,
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
    },
    methods: {
        ...mapActions(['findUsers']),
    },
};
</script>
