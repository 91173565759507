<template>
    <v-container>
        <v-row align="center">
            <v-col class="py-0" cols="12" md="6">
                <v-select v-model="singleOrder.field" :items="groupCols" simple />
            </v-col>
            <v-col class="py-0" cols="12" md="3">
                <v-select v-model="singleOrder.sort" :items="sortOptions" simple />
            </v-col>
            <v-col class="py-0" cols="12" md="1">
                <v-btn icon v-if="allowAddingSort" @click="addOrder">
                    <v-icon v-if="editIdx === -1">mdi-plus</v-icon>
                    <v-icon v-else>mdi-pencil</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-chip-group column>
                    <v-chip
                        label
                        outlined
                        close
                        :color="editIdx === index ? 'pink darken-2' : 'indigo darken-2'"
                        v-for="(o, index) in order"
                        :key="o.field"
                        @click:close="removeOrder(index)"
                        @click="editOrder(index)"
                    >
                        {{ labelField(o.field) }} - {{ labelSort(o.sort) }}
                    </v-chip>
                </v-chip-group>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { issuesHeaders } from '@/config/views';

export default {
    name: 'selector-order',
    data: () => ({
        order: [],
        singleOrder: {
            field: undefined,
            sort: 1,
        },
        editIdx: -1,
    }),
    components: {},
    mounted() {
        this.order = this.value;
    },
    props: {
        value: {},
    },
    watch: {
        order: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.order = value;
        },
    },
    computed: {
        groupCols() {
            return [
                {
                    value: '',
                    text: '-',
                },
            ].concat(
                issuesHeaders.map((h) => {
                    const col = Object.assign({}, h);
                    col.text = this.$t(col.text);
                    return col;
                }),
            );
        },
        sortOptions() {
            return [
                {
                    text: this.$t('views.order.-1'),
                    value: -1,
                },
                {
                    text: this.$t('views.order.1'),
                    value: 1,
                },
            ];
        },
        allowAddingSort() {
            return this.singleOrder.field;
        },
    },
    methods: {
        labelField(field) {
            const col = this.groupCols.find((c) => c.value === field);
            return col ? col.text : '-';
        },
        labelSort(sort) {
            const col = this.sortOptions.find((c) => c.value === sort);
            return col ? col.text : '-';
        },
        editOrder(index) {
            this.editIdx = index;
            this.singleOrder = this.order[index];
        },
        addOrder() {
            if (this.editIdx === -1) {
                this.order.push(this.singleOrder);
            } else {
                this.order.splice(this.editIdx, 1, this.singleOrder);
            }
            this.singleOrder = {
                field: undefined,
                sort: 1,
            };
            this.editIdx = -1;
        },
        removeOrder(index) {
            this.order.splice(index, 1);
        },
    },
};
</script>
