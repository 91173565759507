<template>
    <v-select v-model="localRoles" :items="selector" chips :label="label" multiple></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'selector-roles',
    data: () => ({
        localRoles: [],
    }),
    created() {},
    mounted() {
        this.localRoles = this.value;
    },
    props: {
        value: {},
        label: {},
    },
    watch: {
        localRoles: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localRoles = value;
        },
    },
    computed: {
        ...mapGetters(['roles']),
        selector() {
            return this.roles.map((r) => {
                return {
                    value: r,
                    text: this.$t('lists.roles.' + r),
                };
            });
        },
    },
    methods: {},
};
</script>
